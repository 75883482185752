<template>
  <!-- 结算管理 - 预付款记录管理 -->
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">预付款列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">预付款记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="操作人" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">操作人:</span>
              <el-input
                v-model="retrievalData.operatorName"
                clearable
                placeholder="请输入操作人"
                size="small"
              ></el-input>
            </div>
            <div title="确认标识" class="searchboxItem ci-full">
              <span class="itemLabel">确认标识:</span>
              <el-select
                size="small"
                v-model="retrievalData.advancechargeState"
                clearable
              >
                <el-option
                  v-for="item in confirmationData"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >

              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                style="margin-right: 22px"
                round
                @click="advanceRecordExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="80px"
              /><el-table-column
                label="预付款日期"
                align="left"
                show-overflow-tooltip
                prop="advancechargeTime"
              />
              <el-table-column
                label="类型"
                align="left"
                show-overflow-tooltip
                prop="advancechargeType"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "ADVANCECHARGE_TYPE",
                      scope.row.advancechargeType
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="金额"
                align="left"
                show-overflow-tooltip
                prop="amountMoney"
              />
              <el-table-column
                label="操作人"
                align="left"
                show-overflow-tooltip
                prop="createName"
              />

              <el-table-column
                label="创建日期"
                align="center"
                show-overflow-tooltip
                ><template slot-scope="scope">{{
                  scope.row.createTime | momentDate
                }}</template>
              </el-table-column>
              <el-table-column
                label="确认标识"
                align="left"
                show-overflow-tooltip
                prop="advancechargeState"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "ADVANCECHARGE_STATE",
                      scope.row.advancechargeState
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="确认人"
                align="left"
                show-overflow-tooltip
                prop="confirmName"
              />
              <el-table-column
                label="确认日期"
                align="center"
                show-overflow-tooltip
                ><template slot-scope="scope">{{
                  scope.row.confirmTime | momentDate
                }}</template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="lookFile(scope.row.advancechargelogId)"
                    >查看附件</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 查看附件 -->
    <el-dialog title="查看附件" :visible.sync="lookFileDialog" top="2%">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="fileListData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column
            label="名称"
            align="center"
            show-overflow-tooltip
            prop="fileName"
          />
          <el-table-column
            label="上传日期"
            align="center"
            show-overflow-tooltip
            min-width="180"
            prop="createTime"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            show-overflow-tooltip
            prop="amountReceivable"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="!scope.row.appendix"
                @click="lookFileUrl(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      title="预览"
      :visible.sync="fileDiaLog"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "InvoicingCompanyManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      retrievalData: {
        // 单位名称
        operatorName: "",
        advancechargeState: "",
      },
      //确认标识 下拉数据
      confirmationData: [],
      compId: this.$route.query.compId,
      //查看附件弹框
      lookFileDialog: false,
      //查看附件详情弹框
      fileDiaLog: false,
      //查看附件数据
      fileListData: [],
    };
  },
  created() {
    this.getAdvanceChargeState();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    // 获取确认标识 -- 码值表
    getAdvanceChargeState() {
      const AdvancechargeState = this.$setDictionary(
        "ADVANCECHARGE_STATE",
        "list"
      );
      for (const key in AdvancechargeState) {
        this.confirmationData.push({
          value: key,
          label: AdvancechargeState[key],
        });
      }
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        // 当前页数
        pageNum: pageNum,
        // 每页条数
        pageSize: this.pageSize,
        compId: this.compId,
      };
      if (this.retrievalData.operatorName) {
        params.operatorName = this.retrievalData.operatorName;
      }
      if (this.retrievalData.advancechargeState) {
        params.advancechargeState = this.retrievalData.advancechargeState;
      }
      this.doFetch({
        url: "/biz/advancecharge/selectAdvancechargeLog",
        params,
        pageNum,
      });
    },
    //导出
    advanceRecordExport() {
      const params = {
        compId: this.compId,
        identity: "20", //20运营
      };
      if (this.retrievalData.operatorName) {
        params.operatorName = this.retrievalData.operatorName;
      }
      if (this.retrievalData.advancechargeState) {
        params.advancechargeState = this.retrievalData.advancechargeState;
      }
      this.$post("/biz/advancecharge/exportCompAdvancechargeLog", params)
        .then((res) => {
          if (res.status == "0") {
            let list = res.data;
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    //查看附件
    lookFile(advancechargelogId) {
      this.advancechargelogId = advancechargelogId;
      this.lookFileDialog = true;
      this.getFileList(advancechargelogId);
    },
    getFileList(advancechargelogId) {
      this.$post("/biz/advancecharge/queryAppendixByLog", {
        advancechargelogId,
      }).then((ret) => {
        if (ret.status == 0) {
          this.fileListData = ret.data || [];
        }
      });
    },
    lookFileUrl(row) {
       let extension = row.fileName.substring(
        row.fileName.lastIndexOf(".") + 1
      );
      this.fileType = extension;
      if (this.fileType == "pdf" || this.fileType == "PDF") {
           this.fileDiaLog = true;
        this.$nextTick(() => {
          pdf.embed(row.appendix, "#pdf-cert1");
        });
      } else {
           this.fileDiaLog = true;
        this.ImgSrc = row.appendix;
      }
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-cascader {
      height: 32px !important;
    }
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.bh /deep/ .el-textarea__inner {
  height: 200px;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
</style>
<style lang="less">
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
